import axios from "axios"
import { APICallWithCache, bbProcessEnv } from "../../helpers"
import { ONE_DAY_IN_MILLISECONDS } from "../../constants"

export default {
  get: async ({ xloid, nmls, pricing_grid_defaults, program_preference }) => {
    const params = {
      loid: xloid,
      include_trend: false,
      ...pricing_grid_defaults
    }
    if (nmls) {
      params.nmls = nmls
    }
    if (program_preference) {
      params.program_preference = program_preference
    }
    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/rateflow/price.json`,
        {
          params: params,
          withCredentials: true
        }
      )
      const res = {
        pricing: response.data.results
      }
      return res
    } catch (error) {
      console.log(error)
    }
  },
  getAssumptions: async logID => {
    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/loan-assumptions.json`,
        {
          params: {
            rateflow_log_id: logID
          },
          withCredentials: true
        }
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  log: async ({ quote_id, include_request = false, include_cards = false }) => {
    try {
      let id = ""
      if (Array.isArray(quote_id)) {
        id = quote_id.join(",")
      } else {
        id = quote_id
      }
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/api/v1/rateflow-log`,
        {
          params: {
            id,
            include_request,
            include_cards
          },
          withCredentials: true
        }
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  logPublic: async ({
    quote_id,
    include_request = false,
    include_cards = false
  }) => {
    try {
      let id = ""
      if (Array.isArray(quote_id)) {
        id = quote_id.join(",")
      } else {
        id = quote_id
      }
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/api/v1/rateflow-log-public`,
        {
          params: {
            id,
            include_request,
            include_cards
          },
          withCredentials: true
        }
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  getRequest: async ({ id }) => {
    try {
      const response = await APICallWithCache({
        url: `${bbProcessEnv.API_URL}/api/v1/rateflow-log-request`,
        params: {
          id
        },
        cacheEnabled: true,
        // five days in milliseconds
        expires: 5 * ONE_DAY_IN_MILLISECONDS
      })

      return response
    } catch (error) {
      console.log(error)
    }
  }
}
