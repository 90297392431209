import axios from "axios"
import { bbProcessEnv } from "../../helpers"
import { FeeList } from "../../types"

export default {
  get: async ({ quote_id }: { quote_id: string }): Promise<FeeList> => {
    // return [
    //   {
    //     label: "Deed Fee (County)",
    //     amount: 27,
    //     type: "State"
    //   },
    //   {
    //     label: "Mortgage Fee (County)",
    //     amount: 257,
    //     type: "State"
    //   },
    //   {
    //     label: "Mortgage Intangible Tax (State)",
    //     amount: 800,
    //     type: "State"
    //   },
    //   {
    //     label: "Mortgage Tax (State)",
    //     amount: 1400,
    //     type: "State"
    //   },
    //   {
    //     label: "Deed Tax (State)",
    //     amount: 3000,
    //     type: "State"
    //   },
    //   {
    //     label: "Title Search Fee",
    //     amount: 345,
    //     type: "ThirdParty"
    //   },
    //   {
    //     label: "Appraisal",
    //     amount: 650,
    //     type: "ThirdParty"
    //   },
    //   {
    //     label: "Endorsement",
    //     amount: 675,
    //     type: "ThirdParty"
    //   },
    //   {
    //     label: "Settlement Fee",
    //     amount: 850,
    //     type: "ThirdParty"
    //   },
    //   {
    //     label: "Lender Title Policy",
    //     amount: 2075,
    //     type: "ThirdParty"
    //   },
    //   {
    //     label: "Admin Fee",
    //     amount: 1425,
    //     type: "fee"
    //   }
    // ]

    let id = ""
    if (Array.isArray(quote_id)) {
      id = quote_id.join(",")
    } else {
      id = quote_id
    }

    let url = `${bbProcessEnv.API_URL}/api/v1/fees`

    try {
      const response = await axios.get(url, {
        params: {
          quote_id: id
        }
      })
      return response.data?.status ? [] : response.data
    } catch (error) {
      console.log(error)
      return []
    }
  }
}
