import { postMessagePromise } from "../../iframeCommunication"
import { API_MSG_PROMISE, BB_EXIT_POP_UP_STORAGE_KEY } from "../../constants"
import {
  openExitPopUp,
  shouldShowExitPopUp,
  openLeadWorkflow,
  openContact,
  updateGlobalModalConfig
} from "../../iframeCommunication/utils"
import { getStorage, setStorage } from "../../helpers"
import { openMortgageCalc } from "../../iframeCommunication/utils/mortgage-calc"

/**
 * Call to the iframe and return the promise
 * params: { api, fn, args }
 * api: api name (wizard, lead, location, pricing, affordability, lo, loanCalc, reviews, subscription, contactForm, events, taxes_and_insurance, fees, rateTable)
 * fn: function name
 * args: array of arguments
 * */
const callToChildEmbed = (params, action = API_MSG_PROMISE) => {
  if (window.frames.length >= 1) {
    let config = null
    if (BB.api && BB.api.getState() && BB.api.getState()["bbConfig"]) {
      config = {
        wrapperID: BB.api.getState()["bbConfig"]["wrapperID"],
        widgetType: BB.api.getState()["bbConfig"]["type"],
        app_key: BB.api.getState()["bbConfig"]["app_key"]
      }
    } else {
      config = BB.config
    }
    const iframeID = `${config.wrapperID}-${config.widgetType}-${config.app_key}`
    const target = document.getElementById(iframeID)
    return postMessagePromise({
      params,
      target: target.contentWindow,
      win: window,
      action
    })
  }
}

let storedDefaults = null

const API = (function () {
  const getGlobalModalIframeID = app_key => {
    try {
      const selector = `[id^="bb-global-modal-"][id$="${app_key}"]`
      const element = document.querySelector(selector)

      // Check if the element was found
      if (!element) {
        console.log("Element not found")
      }

      return element.id
    } catch (error) {
      console.error("An error occurred:", error)
      return null
    }
  }

  return {
    setConfig(config) {
      if (!config) {
        console.error(`Error: missing "config" object.`)
        return
      }

      if (!storedDefaults) {
        storedDefaults = { ...BB.config.defaults }
      }

      BB.config.defaults = { ...storedDefaults, ...config }

      const iframeID = getGlobalModalIframeID(this.app_key)

      updateGlobalModalConfig(
        {
          config: BB.config
        },
        iframeID
      )

      BB.api.setWidgetConfig(BB.config)
      console.group("New BB config")
      console.table(BB.config.defaults)
      console.groupEnd()
    },
    leadSubmit(params) {
      const state = BB.api.getState()
      const app_key = BB.config.app_key
      const config = state.config?.[app_key]?.config

      if (!params.details.loid && config?.xloid) {
        params.details.loid = config.xloid
      }

      return callToChildEmbed({
        api: "lead",
        fn: "leadManage",
        args: [
          {
            formData: params,
            srcComponent: "api"
          }
        ]
      })
    },
    getLeadWorkflowValues(app_key = BB.config.app_key) {
      return callToChildEmbed(
        { storageKey: "lw-" + app_key },
        "getLeadWorkflowValues"
      )
    },
    workflowInit(values, goToLastAnswer = true, step) {
      if (values || step) {
        const val = {}

        if (values) {
          val.values = { steps: values }
        }
        if (goToLastAnswer) {
          val.goToLastAnswer = goToLastAnswer
        }
        if (step) {
          val.currentStep = step
        }

        BB.api.handleFrameTasks({
          action: "saveConfigFromChild",
          data: {
            wizard: { ...val }
          },
          app_key: BB.config.app_key
        })
      }
    },
    openLeadWorkflowModal(iframeID) {
      iframeID = iframeID || getGlobalModalIframeID(this.app_key)
      openLeadWorkflow(iframeID)
    },
    openContactModal(iframeID) {
      iframeID = iframeID || getGlobalModalIframeID(this.app_key)
      openContact(iframeID)
    },
    openMortgageCalc(iframeID) {
      iframeID = iframeID || getGlobalModalIframeID(this.app_key)
      openMortgageCalc(iframeID)
    },
    openExitPopUp(force) {
      const showExitPopUp = shouldShowExitPopUp()
      if (force || showExitPopUp) {
        const iframeID = getGlobalModalIframeID(this.app_key)
        openExitPopUp(iframeID)
      }
    },
    // Clean up the exit pop up stored data
    cleanExitPopUpStorage() {
      setStorage(
        {
          exitPopUpSubscribed: false,
          exitPopUpSeen: false,
          exitPopUpLastSeen: 0
        },
        BB_EXIT_POP_UP_STORAGE_KEY
      )
    },
    // Log the exit pop up stored data
    logExitPopUpStorage() {
      console.log("Exit Pop Up Storage", getStorage(BB_EXIT_POP_UP_STORAGE_KEY))
    },
    openModal(type) {
      let target
      const iframeID = getGlobalModalIframeID(this.app_key)
      switch (type) {
        case "mortgageCalc":
          openMortgageCalc(iframeID)
          break
        case "rateSubscription":
          target = `${process.env.API_URL}/external/rate_subscription_modal`
          break
        case "contactForm":
          this.openContactModal(iframeID)
          break
        case "contactFormLegacy":
          target = `${process.env.API_URL}/external/bt_contact_modal`
          break
        case "dynamicForm":
          target = `${process.env.API_URL}/external/form_modal`
          break
        case "leadWorkflow":
          this.openLeadWorkflowModal(iframeID)
          break
        default:
          console.error(`There is no modal ${type}.`)
          break
      }

      target &&
        this.handleFrameTasks({
          action: "modal-open",
          exposedAppKey: BB.config.app_key,
          target
        })
    },
    rateflow: {
      price: function (formData) {
        if (!formData || typeof formData !== "object") {
          console.error(`Error: "price" require arguments fromData object.`)
          return
        }
        const state = BB.api.getState()

        const app_key = BB.config.app_key
        const { rateflow_id } = state.config[app_key].config

        return callToChildEmbed({
          api: "wizard",
          fn: "getPricing",
          args: [rateflow_id, formData]
        })
      },
      scenarios: function (params) {
        // if (!id) {
        //   console.error(`Error: "scenarios" require arguments: id`)
        //   return
        // }
        const state = BB.api.getState()
        const app_key = BB.config.app_key
        const loid = state.config[app_key].config.lo.uid

        return callToChildEmbed({
          api: "pricing",
          fn: "get",
          args: [
            {
              xloid: loid,
              nmls: null,
              pricing_grid_defaults: params
            }
          ]
        })
      },
      log: function (quote_id, include_request = true, include_cards = true) {
        return callToChildEmbed({
          api: "pricing",
          fn: "logPublic",
          args: [{ quote_id, include_request, include_cards }]
        })
      }
    },
    loanCalc(params) {
      if (!params) {
        console.error(`Error: "loanCalc" require arguments.`)
        return
      }
      const state = BB.api.getState()
      const app_key = BB.config.app_key
      const config = state.config[app_key].config
      const loid = config.lo.uid

      const paramsWithSettings = { app_key, loid, ...params }

      return callToChildEmbed({
        api: "loanCalc",
        fn: "getLoanCalc",
        args: [null, paramsWithSettings]
      })
    },
    help() {
      console.log("BB API methods")
      console.group("openModal")
      console.table([
        {
          Argument: "mortgageCalc",
          Description:
            "Open Mortgage Calculator modal. Deprecated, use the new method openMortgageCalc() instead."
        },
        {
          Argument: "rateSubscription",
          Description: "Open Rate Subscription Modal"
        },
        {
          Argument: "contactForm",
          Description: "Open Contact Form Modal"
        },
        {
          Argument: "dynamicForm",
          Description: "Open Dynamic Form Modal"
        },
        {
          Argument: "leadWorkflow",
          Description:
            "Open the Leas Workflow Modal. Deprecated, use the new method openLeadWorkflowModal() instead."
        }
      ])
      console.groupEnd()
      console.group("lead")
      console.table([
        {
          Method: "leadSubmit",
          Arguments: "formData",
          Description: "support for submitting raw leads"
        }
      ])
      console.groupEnd()

      console.group("lead workflow")
      console.table([
        {
          Method: "workflowInit",
          Arguments:
            "formData(optional), goToLastAnswer(default: true), step(optional)",
          Description:
            "open the lead worflow modal with the passed values and step."
        },
        {
          Method: "getLeadWorkflowValues",
          Arguments: "app_key(optional)",
          Description: "Get the lead workflow values based on the app_key."
        },
        {
          Method: "openLeadWorkflowModal",
          Arguments: "",
          Description: "Open the lead workflow modal."
        }
      ])
      console.groupEnd()

      console.group("rateflow")
      console.table([
        {
          Method: "price",
          Arguments: "{...formData}",
          Description: "get rateflow data and returns a promise."
        },
        {
          Method: "scenarios",
          Arguments: "{...pricing_grid_defaults}",
          Description: "get rateflow scenarios and returns a promise."
        },
        {
          Method: "log",
          Arguments: "quote_id, include_request, include_cards",
          Description: "get rateflow log data and returns a promise."
        }
      ])
      console.groupEnd()

      console.group("Loan Calc")
      console.table([
        {
          Method: "loanCalc",
          Arguments: "{...params}",
          Description: "Loan calc API, returns a promise."
        }
      ])
      console.groupEnd()
      console.group("Mortgage Calc")
      console.table([
        {
          Method: "openMortgageCalc",
          Arguments: "",
          Description: "Open Mortgage Calculator modal."
        }
      ])
      console.groupEnd()
      console.group("Exit Pop Up")
      console.table([
        {
          Method: "openExitPopUp",
          Arguments: "force",
          Description:
            "Open the exit pop up modal. If force is true, it will open the modal regardless of the data on the user local storage."
        },
        {
          Method: "cleanExitPopUpStorage",
          Description:
            "Just for test/debug clean up the exit pop up stored data"
        },
        {
          Method: "logExitPopUpStorage",
          Description: "Just for test/debug log the exit pop up stored data"
        }
      ])
      console.groupEnd()
    }
  }
})()

export default API
